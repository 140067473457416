import React from 'react';

const Menu = (props) => {
  return (
    <aside className="menu page__menu">
      <div className="menu__inner">
        <div className="logo logo--menu">
          <img className="logo__img" src="img/logo_eurotech_380.png" width="191" height="35" />
        </div>

        <ul className="menu-list">
          <li className="menu-list__item menu-list__item--active">
            <div className="menu-list__icon-block">
              <svg className="menu-list__icon" width="22" height="25" viewBox="0 0 22 25">
                <path
                  d="M19.5312 0H2.34375C1.09375 0 0 1.09375 0 2.34375V22.6562C0 23.9062 1.09375 25 2.34375 25H19.5312C20.7812 25 21.875 23.9062 21.875 22.6562V2.34375C21.875 1.09375 20.7812 0 19.5312 0ZM6.25 21.25C6.25 21.5625 5.9375 21.875 5.625 21.875H3.75C3.4375 21.875 3.125 21.5625 3.125 21.25V19.375C3.125 19.0625 3.4375 18.75 3.75 18.75H5.625C5.9375 18.75 6.25 19.0625 6.25 19.375V21.25ZM6.25 15C6.25 15.3125 5.9375 15.625 5.625 15.625H3.75C3.4375 15.625 3.125 15.3125 3.125 15V13.125C3.125 12.8125 3.4375 12.5 3.75 12.5H5.625C5.9375 12.5 6.25 12.8125 6.25 13.125V15ZM12.5 21.25C12.5 21.5625 12.1875 21.875 11.875 21.875H10C9.6875 21.875 9.375 21.5625 9.375 21.25V19.375C9.375 19.0625 9.6875 18.75 10 18.75H11.875C12.1875 18.75 12.5 19.0625 12.5 19.375V21.25ZM12.5 15C12.5 15.3125 12.1875 15.625 11.875 15.625H10C9.6875 15.625 9.375 15.3125 9.375 15V13.125C9.375 12.8125 9.6875 12.5 10 12.5H11.875C12.1875 12.5 12.5 12.8125 12.5 13.125V15ZM18.75 21.25C18.75 21.5625 18.4375 21.875 18.125 21.875H16.25C15.9375 21.875 15.625 21.5625 15.625 21.25V13.125C15.625 12.8125 15.9375 12.5 16.25 12.5H18.125C18.4375 12.5 18.75 12.8125 18.75 13.125V21.25ZM18.75 8.75C18.75 9.0625 18.4375 9.375 18.125 9.375H3.75C3.4375 9.375 3.125 9.0625 3.125 8.75V3.75C3.125 3.4375 3.4375 3.125 3.75 3.125H18.125C18.4375 3.125 18.75 3.4375 18.75 3.75V8.75Z"
                  fill="#141414" fillOpacity="0.9"/>
              </svg>
            </div>

            <a className="menu-list__link" href="#">Анализ спроса</a>
          </li>
        </ul>

        <div className="account menu__account">
          <div className="account__inner">
            <div className="account__logo">
              <img className="account__img" src="img/logo.jpg" width="61" height="61" />
            </div>
            <div className="account__name">
              <div className="account__name-text">Дмитрий Антушев</div>
              <div className="account__company-text">ООО ЕВРОТЕК</div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default Menu;
