import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAllProducts = createAsyncThunk(
  'data/fetchAllProducts',
  async (_args, { extra: api }) => {
    const { data } = await api.get('/product/all');

    return data;
});

export const createProducts = createAsyncThunk(
  'data/createProducts',
  async ({ products }, { extra: api }) => {
    await api.post('/product/', { products });
});

export const fetchAllProjects = createAsyncThunk(
  'data/fetchAllProjects',
  async (_args, { extra: api }) => {
    const { data } = await api.get('/project/all');

    return data;
  }
)

export const createProject = createAsyncThunk(
  'data/createProject',
  async ({ products }, {extra: api}) => {
    console.log('Массив товаров для создания проекта: ', products);
    await api.post('/project/', { products });
  }
)

export const deleteAll = createAsyncThunk(
  'data/deleteAll',
  async (_args, {extra: api}) => {
    await api.delete('/deleteAll/');
  }
)
