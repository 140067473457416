export const NameSpace = {
  DATA: 'DATA',
  PRODUCTS: 'PRODUCTS'
}

export const AppRoute = {
  Root: '/',
  Analysis: '/analysis'
}

export const SortType = {
  TOTAL_SHOW_ASC: 'TOTAL_SHOW_ASC',
  TOTAL_SHOW_DESC: 'TOTAL_SHOW_DESC',
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
}
