import { NameSpace } from '../../const.js';

export const getAllProducts = (state) => {
  return state[NameSpace.DATA].products;
}

export const getAllProjects = (state) => {
  return state[NameSpace.DATA].projects;
}

export const getStatusCreateNewProject = (state) => {
  return state[NameSpace.DATA].hasCreateNewProject;
}

export const getStatusDeleteAllData = (state) => {
  return state[NameSpace.DATA].hasDeleteAllData;
}
