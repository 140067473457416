import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AppRoute } from '../../const';

import { fetchAllProducts, fetchAllProjects } from '../../store/api-actions.js';
import { loadProducts } from '../../store/app-data/app-data';
import { getAllProducts } from '../../store/app-data/selectors';
import { generateProductsMock } from '../../utils/mocks';

import AnalysisPage from './../../pages/analysis-page/analysis-page.jsx';
import NotFoundPage from './../../pages/not-found-page/not-found-page.jsx';

const App = () => {
  const dispatch = useDispatch();

  // const products = generateProductsMock(10);
  //
  // dispatch(loadProducts(products));
  useEffect(() => {
    dispatch(fetchAllProducts());
    dispatch(fetchAllProjects());

    const interval = setInterval(() => {
      dispatch(fetchAllProducts());
      dispatch(fetchAllProjects());

    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path={AppRoute.Root} element={ <AnalysisPage /> } />
          <Route path={AppRoute.Analysis} element={ <AnalysisPage /> } />
          <Route path='*' element={ <NotFoundPage /> } />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export { App };
