import { getRandomNumber } from './common';

const names = [
  'RE504836',
  'RE509672',
  'RE525523',
  'RE522878',
  'RE210857',
  'RE587793',
  'RE587794',
  'AL215054',
  'L214634',
  'SJ11792',
  'RE273801',
  'RE187966',
  'RE284091',
  'AL172780',
  'RE205726',
  'RE24619',
  '320/05764',
  '320/07382',
  '320/A7227',
  '320/B4420',
  '332/D5584A',
  '333/C6860',
  'P550520',
  'P550880',
  'P550588',
  'P569206',
  'P550428',
  'P164384',
  'P164375',
  'P550848',
  'P550778',
  'P621984',
  'P621983',
  'P551551',
  'LF16015',
  'FF5421',
  'FS19732',
  'FS1040',
  'FS1280',
  'FS1000',
  'LF3970',
  'FF5135',
  'FS1242',
  'LF9070',
  'AF4801',
  'LF14000NN',
  'FF5457',
  'AF25962',
  'FS20202',
  'HF6555',
  'FF5580',
  'WF2071',
  'HF29000',
  'FS19769',
  'AF25963',
  'AF25557',
  'HF6553',
  'LF3977',
  'AF4828',
  'FS19605',
  'WF2126',
  'HF6710',
  'FS1254',
]

export const generateProductsMock = (count) => {
  const products = [];

  for (let i = 0; i < count; i++) {
    products.push({
      id: i,
      name: names[getRandomNumber(0, names.length - 1)],
      totalShow: getRandomNumber(1000, 100000),
      popularPhrase: generatePopularPhrase(10, 'Распределитель 1P40')
    });
  }

  return products;
}

const generatePopularPhrase = (count, product) => {
  const popularPhrase = [];

  for (let i = 0; i < count; i++) {
    popularPhrase.push({
      phrase: `Купить ${product}`,
      show: getRandomNumber(1000, 4000)
    })
  }

  return popularPhrase;
}
